import de from "./de";
import en from "./en";
import nl from "./nl";
import pt from "./pt";

export default {
  de,
  en,
  nl,
  pt,
};
